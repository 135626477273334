import styled, { css } from 'styled-components';

export const TwoColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${({ verticalCenter }) =>
    verticalCenter
      ? css`
          align-items: center;
        `
      : ''}
  > * {
    width: 50%;
  }
  @media (max-width: 767px) {
    > * {
      width: 100%;
    }
  }
`;

export const Column = styled.div`
  /* border: 2px solid #f00; */
  ${({ stretch }) =>
    stretch
      ? css`
          height: auto;
        `
      : ''}
`;
