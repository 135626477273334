import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/seo';
import { FaPhone } from 'react-icons/fa';
import Img from 'gatsby-image';
import ContentHero from '../components/home/ContentHero';
import ContentForm from '../components/home/ContentForm';
import Content1 from '../components/home/Content1';
import Content2 from '../components/home/Content2';
import Content3 from '../components/home/Content3';
import Content5 from '../components/home/Content5';
import { TwoColumns, Column } from '../components/ui/Grid';

import './style.css';
import ContentSteps from '../components/home/ContentSteps';

const Title = styled.h1`
  color: #003471;
  font-weight: 400;
  margin: 0;
  padding: 0.5rem 2rem;
  font-size: 130%;
  @media (max-width: 767px) {
    font-size: 90%;
    font-weight: 800;
    text-align: center;
  }
`;

const Phone = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #003471;
  font-weight: 400;
  padding: 0.5rem 2rem;
  font-size: 130%;
  text-decoration: none;
  svg {
    font-size: 80%;
    margin-right: 8px;
  }
  @media (max-width: 767px) {
    padding: 0rem 2rem 0.5rem;
    justify-content: center;
    font-size: 85%;
    svg {
      font-size: 75%;
      margin-right: 8px;
    }
  }
`;

const Credit = styled.div`
  text-align: center;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.4);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

const Credit2 = styled.div`
  text-align: center;
  padding: 0.5rem;
  background: rgba(50, 50, 50, 0.4);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "gabriel-katri.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO title={`אזרחות רומנית - גבריאל כתרי, עו״ד ונוטריון`} />
      <TwoColumns verticalCenter style={{ background: '#fff' }}>
        <Column>
          <Title>
            גבריאל כתרי, <span style={{ color: '#bababa' }}>עו״ד ונוטריון</span>
          </Title>
        </Column>
        <Phone href="tel:09-9509504">
          09-9509504
          <FaPhone />
        </Phone>
      </TwoColumns>
      <ContentHero />
      <Column>
        <ContentForm />
      </Column>
      <Column>
        <Content1 />
      </Column>
      <TwoColumns>
        <Column>
          <Content2 />
        </Column>
        <Column stretch>
          <Img
            fluid={data.image.childImageSharp.fluid}
            style={{ height: '100%' }}
          />
        </Column>
      </TwoColumns>
      <Column>
        <Content3 />
      </Column>

      <Column>
        <Content5 />
      </Column>
      <Column>
        <ContentSteps />
      </Column>
      <Column>
        <ContentForm />
      </Column>
      <Credit>
        <div style={{ color: '#fff', fontSize: '10px' }}>
          created with ♥ by{' '}
          <a
            href="http://nogita.io"
            target="_blank"
            rel="noreferrer"
            style={{
              color: '#fff',
              fontSize: '10px',
              textDecoration: 'underline',
            }}
          >
            nogita.io
          </a>
        </div>
      </Credit>
      <Credit2>
        <div style={{ color: '#fff', fontSize: '10px' }}>
          design by{' '}
          <a
            href="https://www.landing.co.il/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: '#fff',
              fontSize: '10px',
              textDecoration: 'underline',
            }}
          >
            LāNDING
          </a>
        </div>
      </Credit2>
    </div>
  );
};

export default IndexPage;
