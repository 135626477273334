import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby-link';
import { FaPhone, FaWaze, FaFax, FaEnvelope } from 'react-icons/fa';

const Block = styled.div`
  background: linear-gradient(#003471, #001250);
  color: #fff;
  padding: 2rem 3rem;
  text-align: center;
  @media (max-width: 767px) {
    padding: 1rem;
  }
  h1 {
    font-weight: 700;
  }
`;

const FormStyled = styled.form`
  label {
    display: none;
  }
  input,
  textarea {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    line-height: 1.4;
    min-height: 40px;
    padding: 6px 10px;
  }
`;

const FormFields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const FormField = styled.div`
  width: 100%;
  ${({ full }) =>
    full &&
    css`
      grid-column: span 3;
      @media (max-width: 767px) {
        grid-column: 1;
      }
    `}
`;

const ButtonContainer = styled.div`
  width: 100%;
  grid-column: span 3;
  @media (max-width: 767px) {
    grid-column: 1;
  }
`;

const Button = styled.button`
  width: 100%;
  background-color: hsl(19, 89%, 54%);
  font-size: 150%;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 3px;
  fill: #fff;
  text-align: center;
  padding: 12px 24px;
  :hover {
    background-color: hsl(19, 100%, 60%);
  }
  :active {
    background-color: hsl(19, 70%, 30%);
  }
`;

const Links = styled.div`
  display: flex;
  font-size: 80%;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 767px) {
  }
  @media (min-width: 1024px) {
  }
  a {
    text-decoration: none;
    span {
      color: #fff;
      white-space: nowrap;
    }
    svg {
      fill: #f26522;
      font-size: 80%;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
`;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const ContentForm = () => {
  const [formStatus, setFormStatus] = useState('idle'); // idle, sending, sent, error
  const [formState, setFormState] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const handleReset = event => {
    setFormState({ name: '', phone: '', email: '', message: '' });
    setFormStatus('idle');
  };

  const handleChange = name => event => {
    setFormState({ ...formState, [name]: event.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.target;
    setFormStatus('sending');

    fetch('/.netlify/functions/sendgrid', {
      method: 'POST',
      // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: JSON.stringify({
        form: form.getAttribute('name'),
        ...formState,
      }),
    })
      .then(() => {
        setFormStatus('sent');
        window.gtag_report_conversion();
        // navigate(form.getAttribute('action'));
      })
      .catch(error => {
        setFormStatus('error');
        alert(error);
      });
  };

  return (
    <Block>
      {formStatus === 'sent' && (
        <div>
          <p>
            תודה על פנייתך.
            <br />
            בקשתך נשלחה ותטופל בהקדם.
          </p>
          <ButtonContainer>
            <Button type="button" onClick={handleReset}>
              לבקשה נוספת
            </Button>
          </ButtonContainer>
        </div>
      )}
      {formStatus !== 'sent' && (
        <div>
          <h2>מי זכאי לקבל את האזרחות הרומנית והדרכון הרומני?</h2>
          <h3>
            לבדיקה אישית של כל מקרה נא השאר פרטים וציין בהערות מי עלה מרומניה
            ומתי:
          </h3>
          <FormStyled
            name="contact"
            method="post"
            action="#"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <FormFields>
              <FormField>
                <label htmlFor="field-name">שם</label>
                <input
                  aria-label="field-name"
                  size="1"
                  type="text"
                  name="name"
                  id="field-name"
                  placeholder="שם"
                  required="required"
                  aria-required="true"
                  value={formState.name}
                  onChange={handleChange('name')}
                />
              </FormField>
              <FormField>
                <label htmlFor="field-phone">טלפון</label>
                <input
                  aria-label="field-phone"
                  size="1"
                  type="tel"
                  name="phone"
                  id="field-phone"
                  placeholder="טלפון"
                  required="required"
                  aria-required="true"
                  pattern="[0-9()#&amp;+*-=.]+"
                  title="מותר להשתמש רק במספרים ותווי טלפון (#, -, *, וכו')."
                  value={formState.phone}
                  onChange={handleChange('phone')}
                />
              </FormField>
              <FormField>
                <label htmlFor="field-email">אימייל</label>
                <input
                  aria-label="field-email"
                  size="1"
                  type="email"
                  name="email"
                  id="field-email"
                  placeholder="אימייל"
                  required="required"
                  aria-required="true"
                  value={formState.email}
                  onChange={handleChange('email')}
                />
              </FormField>
              <FormField full>
                <label htmlFor="field-message">הערות</label>
                <textarea
                  aria-label="field-message"
                  name="message"
                  id="field-message"
                  rows="4"
                  placeholder="הערות - מי עלה מרומניה? אנא ציין תאריך עליה מדויק ככל הידוע"
                  required="required"
                  aria-required="true"
                  value={formState.message}
                  onChange={handleChange('message')}
                />
              </FormField>
              <ButtonContainer>
                <Button type="submit">שליחה</Button>
              </ButtonContainer>
            </FormFields>
          </FormStyled>
          <Links>
            <div>
              <a href="tel:09-9509504">
                {' '}
                <FaPhone />
                <span>טלפון: 09-9509504</span>
              </a>
            </div>
            <div>
              <a href="mailto:gabriel@katri.co.il">
                {' '}
                <FaEnvelope />
                <span>אימייל: gabriel@katri.co.il</span>
              </a>
            </div>
            <div>
              <a href="https://waze.com/ul?q=סוקולוב 29 הרצליה 4649736&amp;z=10&amp;navigate=yes">
                {' '}
                <FaWaze />
                <span>סוקולוב 29 הרצליה 4649736 </span>
              </a>
            </div>
            <div>
              <a href="https://waze.com/ul?q=שד' המגינים 39 חיפה 3326511&amp;z=10&amp;navigate=yes">
                {' '}
                <FaWaze />
                <span>שד' המגינים 39 חיפה 3326511</span>
              </a>
            </div>
            <div>
              <a href="tel:09-9586332">
                {' '}
                <FaFax />
                <span>פקס: 09-9586332</span>
              </a>
            </div>
          </Links>
        </div>
      )}
    </Block>
  );
};

export default ContentForm;
