import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
  background-image: linear-gradient(180deg, #48548a 0%, #2e3a43 100%);
  padding: 3rem;
  color: #fff;
  @media (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

const Content3 = () => (
  <Block>
    <h2>התמחות המשרד - אזרחות ודרכון רומני</h2>
    <div>
      <p>
        כיוון שעו”ד כתרי הוא גם נוטריון כל המסמכים הנוטריוניים הנדרשים, ייפויי
        הכח, תרגומים בצורה תקינה מבוצעים על ידו ובפיקוחו האישי, דבר שיש בו כדי
        להימנע מטעויות וחוסר התאמה לדרישות עת הטיפול הלוגיסטי שנעשה במשרד אחד
        והעבודה הנוטריונית מחוץ לאותו משרד.
      </p>
      <p>
        הפעילות של עו”ד כתרי ברומניה החלה עוד ב- 1990 והוא היה בין הראשונים אם
        לא הראשון בהם שהחל לעסוק בכל הנדרש להשגת הדרכון הרומני.
        <br />
        במהלך של למעלה מ -17 שנים של עיסוק בתחום&nbsp; ועד היום, הגיע מספר
        הלקוחות שקיבלו את הדרכון הרומני דרכו לעשרות אלפים וכך נצבר ניסיון רב.
      </p>
      <p>
        שמו של עו”ד כתרי נקשר לעניין האזרחויות במידה כזאת שמתחרים אחדים ראו בשמו
        מותג ואף קנו את השם גבריאל כתרי בפירסומם כדי שהמחפשים על פי השם “גבריאל
        כתרי”, יגיעו אליהם.
      </p>
      <p>
        עו”ד כתרי גם זכה לחותם אמינות של דן אנד ברדסטרית לעסקים נבחרים.
        <br />
        עו”ד כתרי מנהל צוות מיומן הן בארץ והן ברומניה למתן הפתרון הנדרש לכל מקרה
        כולל בבתי משפט בארץ וברומניה במקרים הנדרשים.
      </p>
    </div>
    {/* <h2>אזרחות ודרכון פורטוגלי</h2>
    <div>
      <p>
        הידע והטיפול באזרחות פורטוגלית לצד הרומנית חשובה במקרים רבים, שכן לעיתם
        קל יותר להשיג לאדם זה או אחר, אזרחות פורטוגלית במקום אזרחות רומנית
        ולהיפך, בהתאם לשורשיו המשפחתיים.
      </p>
      <p>
        הפעולות הנדרשות להשגת האזרחות הפורטוגלית לעומת האזרחות הרומנית, קלות
        לאין שיעור, שכן אם באזרחות הרומנית יש להשיג מסמכים ברומניה של היום או
        שטחי רומניה בעבר, כדי להוכיח זיקה לרומניה, לצורך קבלת האזרחות הפורטוגלית
        די להוכיח כי האדם נקי מהרשעות פליליות בארץ או בחו”ל והוא יהודי וכי מוצאו
        או מוצא הוריו, סביו, במדינות לשם הגיעו מגורשי ספרד ופורטוגל (צפון
        אפריקה, ארצות ערב, טורקיה, דרום מזרח אירופה ועוד).
        <br />
        לעו”ד כתרי שיתוף פעולה עם משרד עורכי דין אמין ומנוסה בליסבון שבפורטוגל,
        המבצע את הנדרש לביצוע עבור לקוחותיו.
      </p>
      <p>
        לנוכח כל האמור לעיל, מובטח שכל הפונים לעו”ד גבריאל כתרי, יזכו לטיפול
        נאמן אישי ומקצועי לביצוע כל שניתן להשגת המטרה של קבלת הדרכון האירופי.
      </p>
    </div> */}
  </Block>
);

export default Content3;
