import React from 'react';
import styled, { css } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql } from 'gatsby';

const steps = [
  `איסוף מסמכים בארץ; במשרד הפנים, רבנות, תעודות נישואין מחו"ל, איסוף מסמכים ברומניה הנעשים על ידי משרדנו. בדיקת התאמת רישום של השמות ותאריכי הלידה ברומניה לעומת הרישום בארץ.`,
  `בהעדר התאמה, התאמה מול משרד הפנים בארץ או בבית הדין הרבני או בבית המשפט בארץ.`,
  `סיוע  בהגשת הבקשה לקבלת האזרחות, רק מי שמעוניין, לא חובה.`,
  `תרגום ואישור נוטריוני של התעודות הישראליות; תעודת לידה, תעודת נישואין, גירושין, שינוי שם. במקרים רבים נדרשת נוכחות הלקוח ברומניה אך לא בכל המקרים.`,
  `קביעת תור לקבלת דרכון לאחר השגת התעודות הרומניות.
  הלקוח נדרש להופיע בקונסוליה הרומנית בישראל או ברומניה; מצוייד בכל המסמכים הרומניים הנדרשים לצורך מתן טביעת אצבע וצילום פנים לצורך לקבלת הדרכון ביומטרי.`,
];

const Block = styled.div``;

const Content = styled.div`
  padding: 2rem;
  h2 {
    color: #e6cd24;
  }
  @media (max-width: 767px) {
    padding: 1rem;
    h2 {
      font-size: 110%;
    }
  }
`;

const Items = styled.div`
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  margin-bottom: 2rem;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ flip }) => (flip ? 'flex-end' : 'flex-start')};
  ${({ seperator, flip }) =>
    seperator &&
    css`
      :after {
        content: '';
        position: absolute;
        bottom: -2rem;
        left: 50%;
        width: 30px;
        height: 3rem;
        ${flip
          ? css`
              border-left: 5px dashed #fff;
            `
          : css`
              border-right: 5px dashed #fff;
            `}
        border-radius: 50%;
      }
    `}
`;

const ItemInner = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  width: ${({ full }) => (full ? 100 : 60)}%;
  padding: 1.5rem;
  > *:first-child {
    padding-left: 1rem;
    color: #e6cd24;
    font-size: 150%;
    font-weight: 900;
  }
  > *:last-child {
    color: #fff;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 1.5rem 1rem 1.5rem 0.5rem;
  }
`;

const ContentSteps = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "steps-bg.jpg" }) {
        childImageSharp {
          fluid(fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Block>
      <BackgroundImage
        Tag="section"
        fluid={data.image.childImageSharp.fluid}
        backgroundColor={`#000`}
      >
        <Content>
          <h2>
            שלבי קבלת האזרחות וקבלת הדרכון הרומני בסיוע משרדו של עו"ד גבריאל
            כתרי לאילו שנדרשים לבקש את האזרחות הרומנית כיוון שהיא נשללה מהם,
            הוריהם או סביהם:
          </h2>
          <Items>
            {steps.map((step, i) => (
              <Item key={i} flip={i % 2} seperator={i < steps.length - 1}>
                <ItemInner>
                  <div>{i + 1}.</div>
                  <div>{step}</div>
                </ItemInner>
              </Item>
            ))}
          </Items>
          <h2>
            שלבים נדרשים לקבלת הדרכון לאלו שלא נדרשו לבקש את האזרחות הרומנית:
          </h2>
          <Items>
            <Item>
              <ItemInner full>
                <div>*</div>
                <div>
                  כל השלבים לעיל למעט הצורך בהגשת בקשה לקבלת האזרחות הרומנית
                  בסיוע מתורגמן מוסמך ולמעט הצורך לטוס לרומניה בשלב של רישום
                  התעודות הרומניות.
                </div>
              </ItemInner>
            </Item>
          </Items>
        </Content>
      </BackgroundImage>
    </Block>
  );
};

export default ContentSteps;
