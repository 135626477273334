import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { useMediaQuery } from 'react-responsive';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const Block = styled.div`
  position: relative;
  width: 100%;
  h2 {
    color: #f26522;
  }
`;

const Content = styled.div`
  padding: 3rem 3rem 3rem 35%;
  @media (max-width: 767px) {
    padding: 2rem 1rem;
    color: #fff;
  }
  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
      display: flex;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  svg {
    width: 25px;
    fill: #f26522;
    margin: 0 0 0 10px;
    flex: 0 0 30px;
    font-size: 120%;
    @media (max-width: 767px) {
      margin: 0 0 10px 10px;
      transform: rotate(315deg);
    }
  }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
  background-position: top left;
`;

const InnerList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 2rem;
  font-size: 85%;
  @media (max-width: 767px) {
    margin-right: 1rem;
  }
  li {
    display: flex;
  }
  li > span:first-child {
    color: #f26522;
    margin-left: 10px;
  }
`;

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "romania2.jpg" }) {
        childImageSharp {
          fluid(fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const sources = [];
  if (isTabletOrMobile) {
    sources.push(`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))`);
  } else {
    sources.push(
      `linear-gradient(80deg, rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))`
    );
  }
  sources.push(data.image.childImageSharp.fluid);

  return (
    <Block>
      <BackgroundImageStyled
        Tag="section"
        fluid={sources}
        backgroundColor={`#000`}
      >
        <Content>
          <h2>הזכאים לקבלת אזרחות ודרכון רומני:</h2>
          <ul>
            <li>
              <FaRegArrowAltCircleLeft />
              <span>
                כל מי שנולד ברומניה ונותר אזרח רומניה עד היום, או היה אזרח
                רומניה בעבר.
              </span>
            </li>
            <li>
              <FaRegArrowAltCircleLeft />
              <span>
                בנים, נכדים ו-מאומצים של ילידי רומניה אשר הינם אזרחי רומניה, או
                היו אזרחים בעבר.
              </span>
            </li>
            <li>
              <FaRegArrowAltCircleLeft />
              <span>
                בנים, נכדים ונינים של ילידי רומניה (כולל מאומצים), אלו שהתחתנו,
                שירתו בצבא, למדו באזורים שהיו בעבר בשליטתה של רומניה והיו אזרחים
                של רומניה דאז וכיום אינם. אזורים אלו הם:
              </span>
            </li>
            <InnerList>
              <li>
                <span>*</span>
                <span>
                  ב-בוקובינה (כיום אוקראינה) בתקופה שבין 28.11.1918 ועד
                  28.06.1940.
                </span>
              </li>
              <li>
                <span>*</span>
                <span>
                  ב-בסרביה (כיום רפובליקה מולדובה) בתקופה שבין 27.03.1918 ועד
                  28.06.1940 (חלק קטן גם באוקראינה של היום).
                </span>
              </li>
            </InnerList>
            <li>
              <FaRegArrowAltCircleLeft />
              <span>
                נותרו אזרחים של רומניה הם אלו שיצאו מרומניה בתקופה שבין
                01.01.1950 ועד 20.01.1952 וכן מ-16.05.1964 ועד תחילת שנת 1966
                ולאחר 1990.
              </span>
            </li>
            <li>
              <FaRegArrowAltCircleLeft />
              <span>
                לא נותרו אזרחים של רומניה הם אלו שהיו אזרחי רומניה בעבר, אבל
                יצאו מרומניה שלא בתקופות שנשארו אזרחים כמצויין לעיל.
              </span>
            </li>
          </ul>
        </Content>
      </BackgroundImageStyled>
    </Block>
  );
};

export default Content1;
