import React from 'react';
import styled from 'styled-components';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

const Block = styled.div`
  background-image: linear-gradient(190deg, #ddf8ff 40%, #9dcae6 92%);
  padding: 3rem;
  /* color: #fff; */
  @media (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  svg {
    width: 25px;
    fill: #f26522;
    margin: 0 0 0 10px;
    flex: 0 0 30px;
    font-size: 120%;
    @media (max-width: 767px) {
      margin: 0 0 10px 10px;
      transform: rotate(315deg);
    }
  }
`;

const Content5 = () => (
  <Block>
    <h2>החשיבות של השגת דרכון אירופאי, להלן רשימה לא סגורה של האפשרויות:</h2>
    <div>
      <List>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            היתר לגור ברחבי אירופה, בכל המדינות של האיחוד האירופי המשותף ללא
            מגבלת זמן.
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            לעבוד בכל המדינות של האיחוד האירופי המשותף וגם מחוץ לאיחוד האירופי
            במדינות שלא מאפשרות או קיים קושי להשיג היתר מגורים ועבודה עם הדרכון
            הישראלי כגון, במדינות המזרח הרחוק וארה”ב.&nbsp;
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            מציאת עבודה במקצועות רבים שאין אפשרות להשתלב בהם בהעדר משרות מספיקות
            בארץ כגון אומנות, מוסיקה ועוד.
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            התפתחות מקצועית עקב הזדמנויות רבות וכמעט בלתי מוגבלות לעומת השוק
            המצומצם בארץ.
            <br />
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            ללמוד במוסדות להשכלה גבוהה בעלות מופחתת או ללא עלות כלל וכן מגורים
            במעונות הסטודנטים ללא עלות ונדיעה בתחבורה ציבורית ללא עלות. אפשרויות
            הלימודים באירופה הנן בעלות משמעות רבה לכל מי שלא הצליח או לא מעוניין
            להתקבל למוסדות להשכלה גבוהה בארץ או מעוניין ללמוד בתחום שאינו מצוי
            או לא מפותח דיו בארץ.
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            המגורים באירופה זולים לאין שיעור לעומת עלות המגורים בארץ הן בשכירות
            והן ברכישת דירה.
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            רכישת מקרקעין להשקעה ובכלל מותרת במדינות מסויימות כגון רומניה רק
            לבעלי אזרחות מקומית.
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            טיפולים רפואיים, השתלות שלא מתאפשרות בארץ ניתנות להשגה באירופה
            הגדולה.
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            פיתוח עסקים, סיוע מדינה לקידום עסקים, ניתנים לאזרחים של מדינות
            החברות באיחוד האירופי המשותף
          </p>
        </li>
        <li>
          <FaRegArrowAltCircleLeft />
          <p>
            כניסה למדינות רבות ברחבי העולם המאפשרות כניסה עם דרכון אירופאי, ולא
            מאפשרות כניסה עם דרכון ישראלי.
          </p>
        </li>
      </List>
    </div>
  </Block>
);

export default Content5;
