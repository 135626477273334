import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { TwoColumns, Column } from '../ui/Grid';
import { useMediaQuery } from 'react-responsive';

const Block = styled.div`
  position: relative;
  width: 100%;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  h1 {
    font-weight: 900;
    margin-bottom: 1rem;
  }
  h1,
  h2 {
    color: #482a48;
  }
  @media (max-width: 767px) {
    color: #fff;
    padding: 2rem 1rem;
    h1 {
      color: #fff;
      font-size: 150%;
    }
    h2 {
      color: #fff;
      font-size: 130%;
    }
  }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
  background-position: top left;
`;

const ContentHero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "header.jpg" }) {
        childImageSharp {
          fluid(fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      flag: file(relativePath: { eq: "flag4.png" }) {
        childImageSharp {
          fluid(maxWidth: 130) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const sources = [];
  if (isTabletOrMobile) {
    sources.push(`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`);
  } else {
    sources.push(
      `linear-gradient(90deg, rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9))`
    );
  }
  sources.push(data.image.childImageSharp.fluid);

  return (
    <Block>
      <BackgroundImageStyled
        Tag="section"
        fluid={sources}
        backgroundColor={`#000`}
      >
        <TwoColumns verticalCenter>
          <Column>
            {isTabletOrMobile ? (
              <Center>
                <h1>
                  הדרך הבטוחה והנכונה
                  <br />
                  להשגת אזרחות רומנית
                </h1>
                <h2>
                  וקבלת הזכויות הבלעדיות
                  <br />
                  הניתנות לאזרחי אירופה ורומניה
                </h2>
                <Img
                  fluid={data.flag.childImageSharp.fluid}
                  style={{ width: '69px', marginBottom: '1rem' }}
                />
              </Center>
            ) : (
              <Center>
                <Img
                  fluid={data.flag.childImageSharp.fluid}
                  style={{ width: '69px', marginBottom: '1rem' }}
                />
                <h1>
                  הדרך הבטוחה והנכונה
                  <br />
                  להשגת אזרחות רומנית
                </h1>
                <h2>
                  וקבלת הזכויות הבלעדיות
                  <br />
                  הניתנות לאזרחי אירופה ורומניה
                </h2>
              </Center>
            )}
          </Column>
        </TwoColumns>
      </BackgroundImageStyled>
    </Block>
  );
};

export default ContentHero;
