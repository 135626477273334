import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql } from 'gatsby';

const Block = styled.div``;

const Content = styled.div`
  padding: 3rem;
  color: #fff;
  h2 {
    color: #f26522;
  }
  @media (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

const Content2 = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "city.jpg" }) {
        childImageSharp {
          fluid(fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Block>
      <BackgroundImage
        Tag="section"
        fluid={data.image.childImageSharp.fluid}
        backgroundColor={`#000`}
      >
        <Content>
          <h2>
            גבריאל כתרי,
            <br />
            בוגר משפטים באוניברסיטה העברית בירושלים, הינו עורך דין ונוטריון
          </h2>
          <p>
            <span>
              <strong>עו”ד כתרי,</strong> יליד קלוז’ שברומניה, דובר את השפות
              רומנית, הונגרית, אנגלית ועברית.&nbsp;
            </span>
          </p>
          <p>
            <span>
              בעברו עבד בפרקליטות המדינה ולאחר מכן בסקטור הפרטי, ליטיגציה,
              חוזים, מקרקעין, ירושות, פלילי ועוד.
            </span>
          </p>
        </Content>
      </BackgroundImage>
    </Block>
  );
};

export default Content2;
